// --------------------------------
// TEAM DATA
// --------------------------------
import axiosResiInstance from "../http/axiosResi";
import { ROLE_TEAM_PAGE } from "./user";

export const IS_TEAM = "is_team";
export const USER_TEAM_ROLE = ROLE_TEAM_PAGE;
export const TEAM_PAGE_TYPE = "type";
export const TEAM_PAGE_TYPE_VALUE = "team";
export const TEAM_PAGE_LEADER = "team_leader";

// --------------------------------
// TEAM AGENT DATA
// --------------------------------
export const TEAM_PAGE_AGENT_DATA = "team_page_agent";
export const TEAM_PAGE_ID = "team_page_id";
export const TEAM_PAGE_NAME = "team_page_name";
export const TEAM_PAGE_URL = "team_page_url";
export const TEAM_PAGE_AGENT_ID = "agent_id";
export const IS_TEAM_LEADER = "is_team_leader";

export const IS_FORWARD_TEAM_LEADER_PROFILE_TO_TEAM =
    "is_forward_team_leader_profile_to_team";
export const IS_ALLOW_TEAM_LEADER_TO_VIEW_TEAM_LEADS =
    "is_allow_team_leader_to_view_team_leads";
export const DO_NOT_NOTIFY_TEAM_LEADER = "do_not_notify_team_leader";

// --------------------------------
// TEAM FUNCTIONS
// --------------------------------
export const isUserTeam = (profile) => {
    return profile && profile["role"] === USER_TEAM_ROLE;
};

export const isTeam = (team) => {
    return (
        team &&
        (team[IS_TEAM] ||
            (team[TEAM_PAGE_TYPE] &&
                team[TEAM_PAGE_TYPE] === TEAM_PAGE_TYPE_VALUE))
    );
};

export const isTeamOrTeamAgent = (teamOrAgent) => {
    return isTeam(teamOrAgent) || isAgentAssignedToATeam(teamOrAgent);
};

export const getLeaderIdOfTeam = (team) => {
    if (!isTeam(team)) {
        return "";
    }

    return team[TEAM_PAGE_LEADER] ? team[TEAM_PAGE_LEADER]["id"] : "";
};

export const getLeaderNameOfTeam = (team) => {
    if (!isTeam(team)) {
        return "";
    }

    return team[TEAM_PAGE_LEADER] ? team[TEAM_PAGE_LEADER]["full_name"] : "";
};

// --------------------------------
// TEAM AGENT FUNCTIONS
// --------------------------------
export const isTeamLeaderOfAgentSTeam = (agent) => {
    if (!isAgentAssignedToATeam(agent)) {
        return false;
    }

    return !!agent[TEAM_PAGE_AGENT_DATA][IS_TEAM_LEADER];
};

export const isAgentAssignedToTheTeam = (agent, teamId) => {
    return (
        agent &&
        agent[TEAM_PAGE_AGENT_DATA] &&
        agent[TEAM_PAGE_AGENT_DATA][TEAM_PAGE_ID] === teamId &&
        agent[TEAM_PAGE_AGENT_DATA][TEAM_PAGE_AGENT_ID] === agent.id
    );
};

export const isAgentAssignedToATeam = (agent) => {
    return (
        agent &&
        agent[TEAM_PAGE_AGENT_DATA] &&
        agent[TEAM_PAGE_AGENT_DATA][TEAM_PAGE_AGENT_ID] === agent.id
    );
};

export const isForwardTeamLeaderProfileToTeam = (agent) => {
    if (!isTeamLeaderOfAgentSTeam(agent)) {
        return false;
    }

    return !!agent[TEAM_PAGE_AGENT_DATA][
        IS_FORWARD_TEAM_LEADER_PROFILE_TO_TEAM
    ];
};

export const isAllowTeamLeaderToViewTeamLeads = (agent) => {
    if (!isTeamLeaderOfAgentSTeam(agent)) {
        return false;
    }

    return !!agent[TEAM_PAGE_AGENT_DATA][
        IS_ALLOW_TEAM_LEADER_TO_VIEW_TEAM_LEADS
    ];
};

export const doNotNotifyTeamLeader = (agent) => {
    if (!isTeamLeaderOfAgentSTeam(agent)) {
        return false;
    }

    return !!agent[TEAM_PAGE_AGENT_DATA][DO_NOT_NOTIFY_TEAM_LEADER];
};

export const getLabelOfTeamLeaderOrAgent = (agent) => {
    if (!isAgentAssignedToATeam(agent)) {
        return "";
    }

    return isTeamLeaderOfAgentSTeam(agent) ? "Team Leader" : "Team Agent";
};

export const getTeamIdOfAgentSTeam = (agent) => {
    if (!isAgentAssignedToATeam(agent)) {
        return null;
    }

    return agent[TEAM_PAGE_AGENT_DATA][TEAM_PAGE_ID];
};

export const getNameOfAgentSTeam = (agent) => {
    if (!isAgentAssignedToATeam(agent)) {
        return "";
    }

    return agent[TEAM_PAGE_AGENT_DATA][TEAM_PAGE_NAME];
};

export const getUrlOfAgentSTeam = (agent) => {
    if (!isAgentAssignedToATeam(agent)) {
        return "";
    }

    return agent[TEAM_PAGE_AGENT_DATA][TEAM_PAGE_URL];
};

export const getAgentProfilePageUrl = (agent) => {
    return {
        href: `/agent?agent=${agent.url}`,
        as: `/real_estate_agent/${agent.url}`,
    };
};

export const getTeamProfilePageUrl = (agent) => {
    const urlOfAgentSTeam = getUrlOfAgentSTeam(agent);

    if (!urlOfAgentSTeam) {
        return "";
    }

    return {
        href: `/agent?agent=${urlOfAgentSTeam}`,
        as: `/real_estate_agent/${urlOfAgentSTeam}`,
    };
};

export const getTeamAgentProfilePageUrl = (agent) => {
    if (isForwardTeamLeaderProfileToTeam(agent)) {
        return getTeamProfilePageUrl(agent);
    }

    return getAgentProfilePageUrl(agent);
};

export const deleteMeFromTeam = async (agent) => {
    if (!agent || !isAgentAssignedToATeam(agent)) {
        return false;
    }

    let success = true;

    try {
        await axiosResiInstance.delete(`/team-page-agent/${agent.id}`);
    } catch (e) {
        success = false;
    }

    return success;
};
