import axiosResiInstance from "../http/axiosResi";

export const ROLE_USER = "user";
export const ROLE_CLIENT = "client";
export const ROLE_TEAM_PAGE = "team";

export const REDIRECT_PROPERTY_PARAM = "redirect_property";

export const UNSUBSCRIPTION_TYPES = {
    AgentReviewReply: {
        name: "Agent Review Reply",
        subject: "Reply to your review on Residential.com",
    },
    ShareHome: {
        name: "Share Home",
        subject: "Shared a Home with you from Residential",
    },
};

export const disableSocialSignIn = async (type, accessToken) => {
    if (!type || !accessToken) {
        return false;
    }

    let success = true;

    try {
        await axiosResiInstance.delete(`/auth/social-login/${type}`, {
            params: {
                access_token: accessToken,
            },
        });
    } catch (e) {
        success = false;
    }

    return success;
};

export const setPassword = (password) => {
    return axiosResiInstance.post("/auth/update", {
        password: password,
    });
};

export const updatePassword = (oldPassword, password) => {
    return axiosResiInstance.post("/auth/update-password", {
        password: password,
        old_password: oldPassword,
    });
};

export const updateEmail = (email) => {
    return axiosResiInstance.post("/auth/update", {
        email: email,
    });
};

export const removePhoto = () => {
    return axiosResiInstance.post("/auth/update", {
        photo: "",
    });
};

export const updateProfile = (firstName, lastName, phone) => {
    let data = {
        first_name: firstName,
        last_name: lastName,
        phone: phone,
    };

    return axiosResiInstance.post("/auth/update", data);
};

export const updateAvatar = (photo) => {
    let data = {
        photo: photo,
    };

    return axiosResiInstance.post("/auth/update", data);
};

export const updateEmailUnsubscription = (messageType) => {
    return axiosResiInstance.put("/unsubscription-update", {
        message_type: messageType,
    });
};

export const getProfileUrlWithRedirectProperty = (url) => {
    return {
        url: {
            pathname: "/user/account-settings/profile",
            query: {
                [REDIRECT_PROPERTY_PARAM]: encodeURIComponent(url),
            },
        },
        as: `/user/account-settings/profile?${REDIRECT_PROPERTY_PARAM}=${encodeURIComponent(
            url
        )}`,
    };
};

export const getRedirectPropertyUrl = (query) => {
    if (!query || !query[REDIRECT_PROPERTY_PARAM]) {
        return null;
    }

    return decodeURIComponent(query[REDIRECT_PROPERTY_PARAM]);
};
